import React from 'react';
// Structure imports
import Layout from '@components/structure/Layout';
// Utility imports
import Seo from '@components/utility/Seo';
import CtaButton from '@mui-components/ctaButton';
import { Grid } from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

const RegConfirmationNarcolepsyUnbranded = () => (
	<Layout
		pageType='narcolepsy-unbranded'
		className='narcolepsyUnbranded no-tabs'
		pixelCode='Complete Registration'>
		<Seo
			title='Confirmation of Webinar Registration | Jazz Webinars'
			description=''
		/>
		<div>
			<StaticImage
				className='test-desktop'
				src='../../../static/images/narcolepsy-unbranded-hero.jpg'
				alt='Narcolepsy Hero Banner Desktop on home page'
			/>
			<StaticImage
				className='test-mobile '
				src='../../../static/images/narcolepsy-unbranded-hero-mobile.jpg'
				alt='Narcolepsy Hero Banner mobile on home page'
			/>
			<section className='container firstSection'>
				<Grid container>
					<Grid item xs={12}>
						<div className='back-link-background'>
							<span className='back-link'>
								&#8249; <a href='/narcolepsy/'>Back to Webinar Library</a>
							</span>
						</div>

						<div className='lv-copy'>
							<h1>
								<span>Stay up-to-date on the</span>
								Symptoms <br className='desktopOnly' />
								and Impact <br className='desktopOnly' />
								of Narcolepsy
							</h1>
						</div>
					</Grid>
				</Grid>
			</section>
		</div>
		<section className='smaller-container center'>
			<h2>Thanks for signing up!</h2>
			<div className='gradient-divider center'>
				<hr />
			</div>
			<p className='copy'>
				We’re looking forward to having you in our live webinar session(s)! A
				confirmation email will be sent to your inbox with the details of the
				webinar(s) you've signed up for.
			</p>
			<p>
				<strong>
					In the meantime, why not browse through our Webinars On-Demand video
					library?
				</strong>
			</p>
			<div className='spaceAboveUnder'>
				<Link
					to={`/narcolepsy`}
					state={{ onDemandTabSelected: true }}
					className='cta watch-cta rounded-link'>
					Watch Webinars On Demand
				</Link>
			</div>
		</section>
	</Layout>
);

export default RegConfirmationNarcolepsyUnbranded;
